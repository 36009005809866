import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import emailjs from 'emailjs-com';

/* TODO list
 * - FINISH_FORM     - Clear the form fields, and provide user feedback 
 */

function ContactUs() {
    const [validated, setValidated] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("Hello Greenfield! I am seeking assistance with a software based challenge. Do you have a moment to chat about my current hurdle(s)?");

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        setValidated(true);

        // Send the message to email service
        emailjs.sendForm("service_rkm4q5b", "template_vj7f01p", event.target, "L43x5HDxZZR1h6DIO")
            .then((result) => {
                console.log('Email successfully sent!', result.text);
            }, (error) => {
                console.error('Error sending email:', error.text);
            });

        // TODO - FINISH_FORM
        // Clear form values
        // document.getElementById("desktop-contactForm").reset()
        // document.getElementById("mobile-contactForm").reset()
        // setName("");
        // setEmail("");
        // setMessage("Hello Greenfield! I am seeking assistance with a software based challenge. Do you have a moment to chat about my current hurdle(s)?");
    };

    return (
        <>
            {/* DESKTOP VIEW */}
            <Container className="d-none d-lg-block desktop-contactUs" fluid>
                <Row>
                    <Col xs={{ span: 4, offset: 1 }}>
                        <h2 id="contact-description">Have any questions? We'll do our best to respond back to you within 24 hours.</h2>
                    </Col>
                    <Col xs={{ span: 5, offset: 1 }}>
                        <Form validated={validated} onSubmit={handleSubmit} id='desktop-contactForm'>
                            <Form.Group className="mb-3" controlId="formName" id="desktop-formName">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Your name"
                                    value={name}
                                    name="name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail" id="desktop-formEmail">
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Your email"
                                    value={email}
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                                {/* <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text> */}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formTextArea">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Message (optional)"
                                    id="desktop-formTextarea"
                                    defaultValue="Hello Greenfield! I am seeking assistance with a software based challenge. Do you have a moment to chat about my current hurdle(s)?"
                                    value={message}
                                    name="message"
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Form.Group>
                            <Button type="submit" id="desktop-formSubmit">
                                Send Email &rarr;
                            </Button>
                            {/* TODO - RECAPTCHA */}
                            {/* <div class="g-recaptcha" data-sitekey="6LeuL7gpAAAAAI6yTjXUq2_fO-9fPzShTSOtokwc" data-action="LOGIN"></div> */}
                        </Form>
                    </Col>
                </Row>
            </Container>

            {/* MOBILE VIEW */}
            <Container className="d-block d-lg-none mobile-contactUs" fluid>
                <Row>
                    <Col className="text-center">
                        <h2 id="contact-description">Have any questions? We'll do our best to respond back to you within 24 hours.</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} sm={8} className="mobile-contactForm">
                        <Form validated={validated} onSubmit={handleSubmit} id='mobile-contactForm'>
                            <Form.Group className="mb-3" controlId="mobile-formName" id="mobile-formName">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Your name"
                                    value={name}
                                    name="name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="mobile-formEmail" id="mobile-formEmail">
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Your email"
                                    value={email}
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="mobile-formTextArea">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Message (optional)"
                                    id="mobile-formTextarea"
                                    defaultValue="Hello Greenfield! I am seeking assistance with a software based challenge. Do you have a moment to chat about my current hurdle(s)?"
                                    value={message}
                                    name="message"
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Form.Group>
                            <Button type="submit" id="mobile-formSubmit">
                                Send Email &rarr;
                            </Button>
                            {/* TODO - RECAPTCHA */}
                            {/* <div class="g-recaptcha" data-sitekey="6LeuL7gpAAAAAI6yTjXUq2_fO-9fPzShTSOtokwc" data-action="LOGIN"></div> */}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ContactUs;