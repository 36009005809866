import { useState } from 'react';
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

/* TODO list
 * - JUMP_CODE     - DESCRIPTION OF PROBLEM
 *  
 *  
 */

function WhyUs() {
    return (
        <>
            {/* MOBILE VIEW  */}
            <Container className="whyUs d-block d-lg-none" fluid>
                <Col className="p-5">
                    <Row>
                        <h1>Why Us?</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </Row>
                    <Row>
                        <h2>Lorem ipsum dolor sit amet?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </Row>
                    <Row>
                        <h2>Lorem ipsum dolor sit amet?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </Row>
                    <Row>
                        <h2>Lorem ipsum dolor sit amet?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </Row>
                    <Row>
                        <h2>Lorem ipsum dolor sit amet?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </Row>
                    <Row>
                        <Image
                            id="guy-computer"
                            src="/whyUs/guy-arrows-combo.png" />
                    </Row>
                </Col>
            </Container>

            {/* DESKTOP VIEW */}
            <Container className="whyUs pt-5 d-none d-lg-block" fluid>
                <Row>
                    <Col>
                        <Image
                            id="guy-computer"
                            src="/whyUs/guy-arrows-combo.png" />
                    </Col>
                    <Col>
                        <Row className="py-3">
                            <Col>
                                <h2>Why Us?</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </Col>
                        </Row>
                        <Row className="py-3">
                            <Col>
                                <h3>Lorem ipsum dolor sit amet?</h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p>
                            </Col>
                        </Row>
                        <Row className="py-3">
                            <Col>
                                <h3>Lorem ipsum dolor sit amet?</h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p>
                            </Col>
                        </Row>
                        <Row className="py-3">
                            <Col>
                                <h3>Lorem ipsum dolor sit amet?</h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default WhyUs;