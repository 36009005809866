import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';


function ToastElement() {
    const [showA, setShowA] = useState(true);

    const toggleShowA = () => setShowA(!showA);

    return (
        <>
            <div className='toast-notification'>
                <Toast show={showA} onClose={toggleShowA}>
                    <Toast.Header>
                        <strong className="me-auto">Greenfield Development Team</strong>
                        <small>1 day ago</small>
                    </Toast.Header>
                    <Toast.Body>Woohoo, we're glad you've stopped by! As you can see this site is actively being developed. Please excuse the mess.</Toast.Body>
                </Toast>

            </div>
        </>
    )
}

export default ToastElement;