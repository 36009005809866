import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Stack from 'react-bootstrap/Stack';

/* TODO list
 * - JUMP_CODE     - DESCRIPTION OF PROBLEM
 * - ENHANCE_NAV   - Navigation links don't scale well with viewport
 * - IDEAL_LOGO    - Make the company logo look better 
 * 
 * 
 * 
 */

function NavigationBar() {
  return (
    <>
      <Container fluid>
        <Row className="socials-bar">
          <Col className="d-none d-lg-block text-white" xs={{ span: 1, offset: 0 }} lg={{ span: 7, offset: 0 }}>Follow us on social media</Col>
          <Col className="d-block" xs={{ span: 2, offset: 0 }} lg={{ span: 1, offset: 0 }}><img
            alt="LinkedIN image"
            src="/navigation/linkedin.png"
          />{' '}
          </Col>
          <Col className="d-block" xs={{ span: 2, offset: 0 }} sm={{ span: 1, offset: 0 }} lg={{ span: 1, offset: 0 }}><img
            alt="Twitter image"
            src="/navigation/twitter.png"
          />{' '}
          </Col>
          <Col className="d-none d-lg-block text-white" xs={{ span: 1, offset: 0 }} lg={{ span: 2, offset: 0 }}>Write an email</Col>
          <Col className="d-block" xs={{ span: 2, offset: 5 }} sm={{ span: 1, offset: 8 }} lg={{ span: 1, offset: 0 }}><img
            alt="Email image"
            src="/navigation/drafts.png"
          />{' '}
          </Col>
        </Row>
      </Container>
      {/* TODO - ENHANCE_NAV */}
      <Navbar expand="lg" className="navigation-bar">
        <Navbar.Brand href="/" className="logo">
          {/* TODO - IDEAL_LOGO */}
          <img
            className="d-block d-sm-none"
            alt="Project Greenfield logo"
            src="/navigation/logo.png"
            width="250px"
          />{' '}
          <img
            className="d-none d-sm-block d-md-none"
            alt="Project Greenfield logo"
            src="/navigation/logo.png"
            width="350px"
          />{' '}
          <img
            className="d-none d-md-block d-lg-none"
            alt="Project Greenfield logo"
            src="/navigation/logo.png"
            width="450px"
          />{' '}
          <img
            className="d-none d-lg-block"
            alt="Project Greenfield logo"
            src="/navigation/logo.png"
            width="550px"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="hamburger" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className="navigation-links">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="about">About</Nav.Link>
            <Nav.Link href="blog">Blog</Nav.Link>
            <Nav.Link href="contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavigationBar;