import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import TypeWriterElement from '../components/TypeWriter';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

/* TODO list
 * - JUMP_CODE     - DESCRIPTION OF PROBLEM
 * - CENTER_IMAGE  - Center the image whilst in mobile viewport size (Big offcentered near upper threshold)
 * - CENTER_BUTTON - Determine better way to center the button (xs={4})
 * - VALID_LINK    - Move href to relevant screen
 * - INSERT_IMAGE  - Insert the absolute image into the layout for better scaling of web elements
 * 
 */

function Hero() {
    // EXAMPLE ["Transparent Communication", "Tailored Solutions", "Shared Ownership", "Continuous Improvement"]
    let type_writer_text = ["Tailored Solutions", "Best In Class Development"]

    return (
        <>
            <div className='hero'>
                {/* TODO - INSERT_IMAGE  */}
                <Image
                    className='d-none d-lg-block'
                    id='hero-image-absolute'
                    src='/hero/hero.png'
                    fluid
                />
                <Container fluid className=''>
                    <Row className='justify-content-center justify-content-lg-start'>
                        <Col xs={11} lg={{ span: 6, offset: 0 }} id="hero-header">What can you expect?</Col>
                    </Row>
                    <Row className='justify-content-center justify-content-lg-start'>
                        <Col xs={11} lg={{ span: 6, offset: 0 }} id="hero-animated-search">
                            <TypeWriterElement
                                id="hero-animated-search-text"
                                text={type_writer_text}
                                className="" />
                        </Col>
                    </Row>
                    <Row className='justify-content-center d-lg-none'>
                        <Col xs={12} lg={6}>
                            {/* TODO - CENTER_IMAGE */}
                            <Image
                                src='/hero/hero.png'
                                fluid
                            />
                        </Col>
                    </Row>
                    <Row className='justify-content-center justify-content-lg-start'>
                        <Col xs={11} lg={{ span: 6, offset: 0 }} id="hero-description">Here are Greenfield Solutions we make your problems our problems. We like to ask a lot of questions upfront to get to know your product inside and out. We only make solutions based on how we would do them in-house.</Col>
                    </Row>
                    <Row className='justify-content-center justify-content-lg-start'>
                        {/* TODO - CENTER_BUTTON */}
                        <Col xs={4} lg={{ span: 6, offset: 0 }} id="hero-button">
                            {/* TODO - VALID_LINK */}
                            <Button href="#contactUs">Learn more</Button>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default Hero;