import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


/* Potential candidate(s)
 * https://codepen.io/johnblazek/pen/nxgVPR
 */


/* TODO list
 * - JUMP_CODE     - DESCRIPTION OF PROBLEM
 * - AUTO_COLORS   - Automate the way the cards are colorized
 * - AUTO_CARDS    - Automate the way cards are generated with the use of const at top 
 * - AUTO_SLIDE    - Automate the slide to continue forward after X time, then pause when interacted with.
 * - ARROW_DESIGN  - Give life to the arrows for the slick-slider mechanism
 */

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        // TODO - ARROW_DESIGN
        <div
            className={className}
            // DEBUG CODE BELOW
            // style={{ ...style, display: "block", background: "red", height: "100%", width: "30px" }}
            style={{ ...style, display: "block", opacity: "0", height: "100%", width: "30px" }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        // TODO - ARROW_DESIGN
        <div
            className={className}
            // DEBUG CODE BELOW
            // style={{ ...style, display: "block", background: "green", height: "100%", width: "30px" }}
            style={{ ...style, display: "block", opacity: "0", height: "100%", width: "30px" }}
            onClick={onClick}
        />
    );
}

function Highlights() {
    // TODO - AUTO_COLORS
    const highlightBackgrounds = {
        "color1": "#7FB42850",
        "color2": "#29560620",
        "color3": "#EBF9DD",
    }

    const highlightSettings = {
        // Note: This is the base setting for everything within slick-slider
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: "linear",
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,

        // Note: Breakpoint affects everything under its value 
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <>
            <h2 className="highlight-header-primary">Highlights of </h2>
            <h2 className="highlight-header-contrast">Project Greenfield Solutions</h2>
            {/* TODO - AUTO_SLIDE */}
            <div className="slider-container">
                <Slider {...highlightSettings}>
                    <div>
                        {/* TODO - AUTO_CARDS */}
                        <Card style={{ backgroundColor: highlightBackgrounds.color3 }}>
                            <Card.Img variant="top" src="/highlights/search_monitor.png" />
                            <Card.Body>
                                <Card.Title>SEO Optimization</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card style={{ backgroundColor: highlightBackgrounds.color2 }}>
                            <Card.Img variant="top" src="/highlights/settings_code.png" />
                            <Card.Body>
                                <Card.Title>Website Performance Optimization</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card style={{ backgroundColor: highlightBackgrounds.color1 }}>
                            <Card.Img variant="top" src="/highlights/puzzles.png" />
                            <Card.Body>
                                <Card.Title>Website Hosting Management</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card style={{ backgroundColor: highlightBackgrounds.color3 }}>
                            <Card.Img variant="top" src="/highlights/pen_and_paper.png" />
                            <Card.Body>
                                <Card.Title>Tailored Solutions</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card style={{ backgroundColor: highlightBackgrounds.color2 }}>
                            <Card.Img variant="top" src="/highlights/settings.png" />
                            <Card.Body>
                                <Card.Title>Service Configuration</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card style={{ backgroundColor: highlightBackgrounds.color1 }}>
                            <Card.Img variant="top" src="/highlights/all_directions.png" />
                            <Card.Body>
                                <Card.Title>Areas of Competency</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Card.Text>
                                <Button variant="primary">Go somewhere</Button>
                            </Card.Body>
                        </Card>
                    </div>
                </Slider>

            </div>
        </>
    )
}

export default Highlights;