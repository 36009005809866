import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';

/* TODO list
 * - JUMP_CODE         - DESCRIPTION OF PROBLEM
 * - ENABLE_NEWSLETTER - Get the newsletter functionality to work
 * - SUBMIT_FUNCT      - Add submit functionality 
 */

function Footer() {
    return (
        <>
            <div className="footer">
                {/* DESKTOP VIEW */}
                <Container className="d-none d-lg-block">
                    <Row>
                        <Col>
                            <Image
                                className='d-none d-lg-block'
                                id='desktop-logo'
                                src='/navigation/logo.png'
                                fluid
                            />
                        </Col>
                        <Col>
                            <Form>
                                <Form.Group className="mb-3" controlId="footerEmail">
                                    <Form.Text className="text-muted">
                                        Sign up for company newsletter!
                                    </Form.Text>
                                    {/* TODO - ENABLE_NEWSLETTER */}
                                    <Form.Control type="email" placeholder="(Non-functional at this time)" id="footerEmail" disabled/>
                                    {/* TODO - SUBMIT_FUNCT */}
                                    <Button type="submit" className="footerSubmit" disabled>
                                        Sign up &rarr;
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos.
                        </Col>
                        <Col>
                            <Row>
                                <Nav className="footer-links">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <Nav.Link href="about">About</Nav.Link>
                                    <Nav.Link href="blog">Blog</Nav.Link>
                                    <Nav.Link href="contact">Contact</Nav.Link>
                                </Nav>
                            </Row>
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                <Nav className="footer-links">
                                    <Nav.Link href="/">
                                        <Image
                                            alt="LinkedIN image"
                                            src="/footer/linkedin.png"
                                        />{' '}
                                    </Nav.Link>
                                </Nav>
                                </Col>
                                <Col xs="auto">
                                <Nav className="footer-links">
                                    <Nav.Link href="/">
                                        <Image
                                            alt="Twitter image"
                                            src="/footer/twitter.png"
                                        />{' '}
                                    </Nav.Link>
                                </Nav>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                {/* MOBILE VIEW */}
                <Container className="d-block d-lg-none">
                    <Row>
                        <Col>
                            <Image
                                className='d-block d-lg-none'
                                id='mobile-logo'
                                src='/navigation/logo.png'
                                fluid
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos.
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group className="mb-3" controlId="footerEmail">
                                    <Form.Text className="text-muted">
                                        Sign up for company newsletter!
                                    </Form.Text>
                                    {/* TODO - ENABLE_NEWSLETTER */}
                                    <Form.Control type="email" placeholder="(Non-functional at this time)" id="footerEmail" disabled/>
                                    {/* TODO - SUBMIT_FUNCT */}
                                    <Button type="submit" className="footerSubmit" disabled>
                                        Sign up &rarr;
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="text-center" xs="auto">
                            <Nav className="footer-links">
                                <Stack gap={3}>
                                    <div className='p-2'>
                                        <Nav.Link href="/">Home</Nav.Link>
                                    </div>
                                    <div className='p-2'>
                                        <Nav.Link href="about">About</Nav.Link>
                                    </div>
                                    <div className='p-2'>
                                        <Nav.Link href="blog">Blog</Nav.Link>
                                    </div>
                                    <div className='p-2'>
                                        <Nav.Link href="contact">Contact</Nav.Link>
                                    </div>
                                </Stack>
                            </Nav>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="auto">
                            <Nav.Link href="/">
                                <Image
                                    alt="LinkedIN image"
                                    src="/footer/linkedin.png"
                                />{' '}
                            </Nav.Link>
                        </Col>
                        <Col xs="auto">
                            <Nav.Link href="/">
                                <Image
                                    alt="Twitter image"
                                    src="/footer/twitter.png"
                                />{' '}
                            </Nav.Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer;