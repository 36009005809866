import NavBar from "../panels/NavBar";
import Hero from "../panels/Hero";
import Highlights from "../panels/Highlights";
import ToastElement from '../components/Toast';
import WhyUs from "../panels/WhyUs";
import Services from "../panels/Services";
import ContactUs from "../panels/ContactUs";
import Footer from "../panels/Footer";


export default function Root() {
    return (
      <>
        <ToastElement />
        <NavBar />
        <Hero />
        <Highlights />
        <WhyUs />
        <Services />
        <ContactUs />
        <Footer />
      </>
    );
  }