import { useEffect } from "react";
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import Stack from 'react-bootstrap/Stack';

/* TODO list
 * - JUMP_CODE     - DESCRIPTION OF PROBLEM
 * - NAME_SERVICES - Add meaningful names to the services
 * - FORMAT_TEXT   - Make the formatting look more presentable 
 */


function Services() {
    const [descriptionClasses, setDescriptionClasses] = useState([]);

    useEffect(() => {
        setDescriptionClasses(Array.from(document.getElementsByClassName("serviceDescription")));
    }, [])
    
    // TODO - NAME_SERVICES
    const serviceDescriptions = {
        "AWS": <li>There are many, many services provided within the AWS eco-system. Our Solution Architects will review your use-case and design an optimized infrastructure to suite your needs.</li>,
        "ETL": <><li>Extract - Data from pre-existing database</li><li>Transform - Data to conform into a new or pre-existing database.</li><li>Load - Inserting the data into the new or pre-existing database.</li></>,
        "Web Performance Optimization": <li>Our engineers target Key Performance Indicators (KPI) when reviewing a website for points of constraints.</li>,
        "SEO Optimization": <li>Search Engine Optimization is a very competitive metric for getting in front of the most customers possible. Let us design the biggest, brightest billboard for your website.</li>,
        "Website Hosting": <><li>Have a great idea but don't want to handle the boring maintenance patches and infrastructure development required for scalable applications?</li><li>Let our engineers handle that for you!</li></>,
        // "service6": <li>Service 6 Description</li>,
        // "service7": <li>Service 7 Description</li>,
        // "service8": <li>Service 8 Description</li>,
    };

    // Web Page Functions
    function addHyphens(word) {
        return word.replaceAll(" ", "-")
    }

    function revealDescription(serviceName) {
        for (const element of descriptionClasses) {
            if (element.classList.contains(serviceName)) {
                element.classList.remove("d-none")
            } else {
                element.classList.add("d-none")
            }
        }
    }

    // DESKTOP SERVICE RENDERS
    function renderServiceNameList() {
        return Object.entries(serviceDescriptions).map(([serviceName, description]) => (
            <Col className="service p-2 mt-1 mb-1" xs={12} lg={3} onClick={() => revealDescription(addHyphens(serviceName))}>
                {serviceName}
            </Col>
        ));
    }

    function renderServiceDescriptionList() {
        return Object.entries(serviceDescriptions).map(([serviceName, description]) => (
            <Stack gap={3}>
                <h2 className={addHyphens(serviceName) + " d-none serviceDescription"}>{serviceName}</h2>
                <ul className={addHyphens(serviceName) + " d-none serviceDescription"}>{description}</ul>
            </Stack>
        ));
    }

    // Mobile Services render
    function renderServiceItems() {
        return Object.entries(serviceDescriptions).map(([serviceName, description], index) => (
            <React.Fragment key={index}>
                <Row className='service p-2'>
                    <Col onClick={() => revealDescription(addHyphens(serviceName))}>{serviceName}</Col>
                </Row>
                <Row className={addHyphens(serviceName) + " d-none serviceDescription"}>
                    <Col>
                        <Stack gap={3}>
                            <h2>{serviceName}</h2>
                            {description}
                        </Stack>
                    </Col>
                </Row>
            </React.Fragment>
        ));
    }

    // NON-USED RENDERS
    function renderServiceName(serviceName) {
        return (
            <Col className="service p-2 mt-1 mb-1" xs={12} lg={3}>
                {serviceName}
            </Col>
        );
    }

    function renderServiceDescription(serviceName) {
        return (
            <Stack gap={3}>
                <h2>{serviceName}</h2>
                {serviceDescriptions[serviceName]}
            </Stack>
        );
    }

    return (
        <>
            {/* TODO - FORMAT_TEXT */}
            {/* Mobile View */}
            <div className="services">
                <Container className="d-block d-lg-none">
                    <Row>
                        <Col>
                            <h1>Know a specific service needed?</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Our engineers are gifted in many areas. See our rounded, but not exhaustive list of areas of competency.</p>
                        </Col>
                    </Row>
                    {renderServiceItems()}
                </Container>

                {/* Desktop view */}
                <Container className="d-none d-lg-block">
                    <Row>
                        <Col>
                            <h1>Know a specific service needed?</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Our engineers are gifted in many areas. See our rounded, but not exhaustive list of areas of competency.</p>
                        </Col>
                    </Row>
                    <Row>
                        {renderServiceNameList()}
                    </Row>
                    <Row>
                        {renderServiceDescriptionList()}
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Services;