// https://www.npmjs.com/package/typewriter-effect
import React from 'react';
import TypeWriter from 'typewriter-effect'

function TypeWriterElement(props) {
    let arrayOfStrings = props.text
    let className = props.className

    return (
        <span className={className}>
        <TypeWriter
            options={{
                strings: arrayOfStrings,
                autoStart: true,
                loop: true,
                // delay: 'natural',
                delay: 75,
                // deleteSpeed: 'natural',
                deleteSpeed: 'natural',
                pauseFor: 1500,
            }}

        />
        </span>
    );
}

export default TypeWriterElement;